import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import './styleLanding.css';
import logo from '../../images/poltio-logo-ondark.svg';

import messages from './messages';

const BoardLanding = ({ intl }) => {
  const [triviaCode, setTriviaCode] = useState('');
  const navigate = useNavigate();

  const onSubmit = (e) => {
    e.preventDefault();
    if (triviaCode.length < 2 || isNaN(triviaCode)) {
      return;
    }
    navigate(`/board/${triviaCode}`);
  };

  return (
    <section className="trivia-start">
      <a
        href="http://www.poltio.com"
        className="logo"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src={logo} alt="Poltio" title="Poltio" />
      </a>
      <h2>Trivia</h2>
      <form id="form">
        <input
          autoComplete="off"
          name="q"
          id="boardlandinginput"
          className="field field--large field--underline no-arrows"
          type="number"
          placeholder={intl.formatMessage(messages.EnterTriviaCode)}
          value={triviaCode}
          onChange={(e) => setTriviaCode(e.target.value)}
          onKeyPress={(e) => {
            if (e.key !== 'Enter' && !/^[0-9]$/i.test(e.key)) {
              e.preventDefault();
              return false;
            }
          }}
        />
        <button
          id="boardlandingsubmit"
          type="submit"
          className="button button--primary button--large button--rounded"
          onClick={onSubmit}
        >
          {intl.formatMessage(messages.GoToLeaderboard)}
        </button>
      </form>
      <div
        className="response"
        style={{ marginTop: '0.5rem', height: '1.25rem' }}
      />
    </section>
  );
};

export default injectIntl(BoardLanding);
