import React, { Fragment } from 'react';

const Waiting = ({ messages, formatMessage, id, triviaState = {} }) => (
  <div id="q0" className="card card--question" style={{ margin: 'auto' }}>
    <Fragment>
      <p className="text--large">
        {formatMessage(messages.JoinQuiz)} <br />
        <b style={{ fontWeight: 'bold', fontSize: '24px' }}>
          {'trivia.poltio.com'}
        </b>
        <br />
        {formatMessage(messages.UseCode)}
      </p>
      <div className="countdown countdown--cover">
        <div className="countdown__content">
          <span className="value">{id}</span>
        </div>
      </div>

      {triviaState.mode === 'donate' && (
        <p style={{ fontSize: '1.5rem' }}>
          {formatMessage(messages.TotalFunds)}{' '}
          <span style={{ color: '#009EEB' }}>
            ${triviaState.total_donation}
          </span>
        </p>
      )}

      <div style={{ lineHeight: '2rem', opacity: '0.25' }} id="round_status">
        {formatMessage(messages.WaitingModerator)}
      </div>
    </Fragment>
  </div>
);

export default Waiting;
