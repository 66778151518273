import axios from 'axios';
import { apiURL } from '../core/constants';

const apiWithoutToken = axios.create({
  baseURL: apiURL,
  timeout: 1800,
});

apiWithoutToken.defaults.headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export default apiWithoutToken;
