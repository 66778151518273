import React, { Component } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import Player from './Player/Player';
import Play from './Player';
import LeaderBoard from './Board/LeaderBoard';
import BoardLanding from './Board';
import Login from './Login';

class App extends Component {
  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      Sentry.captureException(error);
    });
  }

  componentDidMount() {
    const href = window.location.href;
    if (
      href.includes('poltio') &&
      href.includes('http') &&
      !href.includes('https')
    ) {
      window.location.replace(href.replace('http', 'https'));
    }
  }

  render() {
    return !window.WebSocket ? (
      <div className="card">
        <div className="card__answer">{'Your browser is not supported!'}</div>
      </div>
    ) : (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Play />} />
          <Route path="/player/:triviaId" element={<Player />} />
          <Route path="/login" element={<Login />} />
          <Route path="/board" element={<BoardLanding />} />
          <Route path="/board/:triviaId" element={<LeaderBoard />} />
          <Route path="/combined-board/:triviaIds" element={<LeaderBoard />} />
          <Route path="*" element={<Play />} />
        </Routes>
      </BrowserRouter>
    );
  }
}

export default App;
