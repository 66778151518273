import React, { useState, useRef, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import { login } from '../../api';
import './style.css';
import cross from '../../images/icons/cross.svg';
import logo from '../../images/poltio-logo.svg';

import messages from './messages';

const Login = ({ intl: { formatMessage } }) => {
  const { search } = useLocation();
  const emailInput = useRef(null);
  const passInput = useRef(null);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    emailInput.current.focus();
  }, []);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    const params = new URLSearchParams(search);
    const returnUrl = params.get('returnUrl');
    const returnId = params.get('returnId');
    const returnString = `${returnUrl ? `/${returnUrl}` : ''}${
      returnId ? `/${returnId}` : ''
    }`;
    const email = emailInput.current.value;
    const pass = passInput.current.value;

    try {
      await login(email, pass, returnString);
    } catch (e) {
      setErrorMessage(`${formatMessage(messages.WrongComb)}`);
    }
  };

  const onChange = () => setErrorMessage(null);

  return (
    <section id="opening">
      <div className="card">
        <div className="card__answer">
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <a
              href="https://trivia.poltio.com"
              style={{
                width: '3rem',
                padding: '0.5rem 1rem',
                alignSelf: 'center',
              }}
            >
              <img
                src={cross}
                alt=""
                style={{ width: '1.5rem', opacity: '0.25' }}
              />
            </a>
            <div>
              <a
                href="https://www.poltio.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ display: 'block', padding: '1rem 1rem 0' }}
              >
                <img src={logo} alt="" style={{ height: '3rem' }} />
              </a>
            </div>
            <div style={{ width: '3rem' }} />
          </div>
        </div>
        <div className="card__answer">
          <div style={{ width: '100%' }}>
            <h2 style={{ opacity: '0.25', letterSpacing: '0.1rem' }}>
              {`${formatMessage(messages.LoginToManage)}`}
            </h2>
            <form
              method="post"
              onSubmit={onFormSubmit}
              style={{ justifyContent: 'center', flexWrap: 'wrap' }}
            >
              <input
                name="email"
                autoComplete="new-password"
                className="field field--large"
                type="email"
                placeholder="Email"
                style={{ marginBottom: '0.5rem' }}
                required
                ref={emailInput}
                onChange={onChange}
              />
              <input
                name="password"
                autoComplete="new-password"
                className="field field--large"
                type="password"
                placeholder="Password"
                style={{ marginBottom: '0.5rem' }}
                required
                ref={passInput}
                onChange={onChange}
              />
              <div
                className="response"
                style={{
                  margin: '0.25em 0',
                  height: '1.25rem',
                  color: 'red',
                }}
              >
                {errorMessage}
              </div>
              <button
                type="submit"
                className="button button--primary button--large"
              >
                {`${formatMessage(messages.Enter)}`}
              </button>
              <br />
              <br />
            </form>
          </div>
        </div>
        <br />
        <br />
      </div>
    </section>
  );
};

export default injectIntl(Login);
