import React, { Fragment, useEffect, useState, useCallback } from 'react';
import toastr from 'toastr';
import { vote, getVoteCounts, getTopPlayers } from '../../../api';
import { setBoardFreq } from '../../../core/constants';
import Image from './Image';
import Countdown from './Countdown';
import Answers from './Answers';
import Followup from './Followup';
import Footer from './Footer';
import Board from './Board';
import './style.css';

const Question = ({
  data,
  question: questionProp,
  index,
  triviaState,
  socketData,
  connected,
  questionIndex,
  isPrepping,
  showResult,
  getEliminatedStatus,
  username,
  messages,
  formatMessage,
}) => {
  const [cdTimer, setCdTimer] = useState(null);
  const [countStarted, setCountStarted] = useState(false);
  const [duration] = useState(data.time_per_question * 1000);
  const [alertThreshold] = useState(data.time_per_question * 1000 - 3000);
  const [secRemaining, setSecRemaining] = useState(data.time_per_question);
  const [timerValue, setTimerValue] = useState(0);
  const [question, setQuestion] = useState(questionProp);
  const [topPlayers, setTopPlayers] = useState([]);
  const [state, setState] = useState({
    votedStatus: false,
    showResults: false,
    showTimer: true,
    isCalculating: false,
    showBoard: false,
  });

  useEffect(() => {
    if (cdTimer || !countStarted) return;

    const id = setInterval(() => {
      setTimerValue((tv) => (tv > 359 ? 360 : tv + 1));
    }, duration / 360);
    setCdTimer(id);
    return () => clearInterval(cdTimer);
  }, [countStarted, cdTimer, duration]);

  useEffect(() => {
    let timerRemaining = Math.ceil(socketData.timer_remaining);
    let timerFixed = timerRemaining < 5 ? 0 : timerRemaining - 5;

    if (Math.abs(secRemaining - timerFixed) > 1) {
      let secs = Math.min(timerFixed, duration / 1000);
      setSecRemaining(secs);
      setTimerValue((1 - (secs * 1000) / duration) * 360);
    } else {
      const floatRemaining = 1 - timerValue / 360;
      const sRemaining = Math.ceil(floatRemaining * (duration / 1000));
      setSecRemaining(sRemaining);
    }

    if (!countStarted && socketData.status === 'active') {
      setCountStarted(true);
    }
  }, [
    data,
    timerValue,
    secRemaining,
    socketData.timer_remaining,
    socketData.status,
    countStarted,
    duration,
  ]);

  const countDownFinish = useCallback(async () => {
    try {
      setState((s) => ({
        ...s,
        showTimer: false,
        isCalculating: true,
        gettingResult: true,
      }));
      setTimeout(async () => {
        const response = await getVoteCounts(question.id);
        const votedAnswer = question.answers.filter(
          (answer) => answer.voted === 1
        )[0];

        setState((s) => ({
          ...s,
          showResults: true,
          isCalculating: false,
          isCorrect:
            votedAnswer &&
            response.data.answers.filter(
              (a) => a.answer_id === votedAnswer.id
            )[0].is_right_answer
              ? true
              : false,
        }));
        setQuestion((q) => ({
          ...q,
          answers: q.answers.map((answer) => {
            return {
              ...answer,
              vote_count: response.data.answers.filter(
                (a) => a.answer_id === answer.id
              )[0].vc,
              is_right_answer: response.data.answers.filter(
                (a) => a.answer_id === answer.id
              )[0].is_right_answer,
            };
          }),
          vote_count: response.data.vc,
        }));
      }, 5000);

      setTimeout(() => {
        getEliminatedStatus();
      }, 8000);

      if (
        triviaState.player_leaderboard &&
        (questionIndex + 1) %
          Math.ceil(
            socketData.total_questions /
              setBoardFreq(socketData.total_questions)
          ) ===
          0 &&
        questionIndex !== socketData.total_questions - 1
      ) {
        setTimeout(async () => {
          const response2 = await getTopPlayers(triviaState.id);
          setTopPlayers(response2.data);
          setState((s) => ({ ...s, showBoard: true }));
        }, 8000);
      }
    } catch (e) {
      console.log(e);
    }
  }, [
    question.id,
    question.answers,
    getEliminatedStatus,
    questionIndex,
    socketData.total_questions,
    triviaState.id,
    triviaState.player_leaderboard,
  ]);

  useEffect(() => {
    if (questionIndex === index && secRemaining === 0 && !state.gettingResult) {
      countDownFinish();
    }
  }, [
    questionIndex,
    index,
    secRemaining,
    countDownFinish,
    state.gettingResult,
  ]);

  const onAnswerClick = async (e, answerId) => {
    e.preventDefault();
    const { votedStatus, showResults } = state;

    if (triviaState.elimination && triviaState.eliminated) {
      document
        .getElementById(`round_status${question.id}`)
        .classList.add('animated', 'shake');
      setTimeout(() => {
        document
          .getElementById(`round_status${question.id}`)
          .classList.remove('animated', 'shake');
      }, 1000);
      return;
    }

    if (votedStatus || triviaState.can_play === 0 || showResults) {
      return;
    }

    setState((s) => ({
      ...s,
      votedStatus: true,
      isCorrect: question.answers.filter((answer) => answer.id === answerId)[0]
        .is_right_answer,
    }));
    setQuestion((q) => ({
      ...q,
      answers: q.answers.map((answer) => ({
        ...answer,
        voted: answer.id === answerId ? 1 : 0,
      })),
      voted: 1,
    }));

    try {
      const { data } = await vote(answerId, triviaState.id);
      if (!data?.trivia?.voted_for_trivia) {
        rollbackVote();
      }
    } catch (e) {
      try {
        await vote(answerId, triviaState.id);
      } catch (e2) {
        toastr.info('Could not vote! Check your internet connection!');
        rollbackVote();
      }
    }
  };

  const rollbackVote = () => {
    setState((s) => ({
      ...s,
      votedStatus: false,
      isCorrect: false,
    }));
    setQuestion((q) => ({
      ...q,
      answers: q.answers.map((answer) => ({
        ...answer,
        voted: 0,
      })),
      voted: 0,
    }));
  };

  const closeBoard = (e) => {
    e.preventDefault();
    setState((s) => ({ ...s, showBoard: false }));
  };

  const { showResults, isCorrect, showTimer, isCalculating, showBoard } = state;
  const progressBar = `${(
    ((index + 1) / socketData.total_questions) *
    100
  ).toFixed(0)}%`;

  return (
    <div
      id={`q${index}`}
      className={`card card--countdown card--sequence ${
        showResults ? 'isAnswered' : ''
      }
          ${questionIndex === index ? 'card--sequence-active' : ''}
          ${questionIndex === index && isPrepping ? 'isShiftingOut' : ''}
          ${questionIndex + 1 === index && isPrepping ? 'isShiftingIn' : ''}
        `}
    >
      {showBoard ? (
        <Fragment>
          <Board
            messages={messages}
            formatMessage={formatMessage}
            players={topPlayers}
            data={data}
            closeBoard={closeBoard}
          />
          <Footer
            messages={messages}
            formatMessage={formatMessage}
            triviaState={triviaState}
            socketData={socketData}
            connected={connected}
            data={data}
            question={question}
            username={username}
          />
        </Fragment>
      ) : (
        <Fragment>
          <Image
            messages={messages}
            formatMessage={formatMessage}
            question={question}
            isCalculating={isCalculating}
          />
          <Countdown
            secRemaining={secRemaining}
            index={index}
            showTimer={showTimer}
            timerValue={timerValue}
            alertThreshold={alertThreshold}
            duration={duration}
          />
          <Answers
            question={question}
            onAnswerClick={onAnswerClick}
            showResults={showResults}
            isCalculating={isCalculating}
            triviaState={triviaState}
            username={username}
          />
          <Followup
            messages={messages}
            formatMessage={formatMessage}
            progressBar={progressBar}
            index={index}
            triviaState={triviaState}
            socketData={socketData}
            showResults={showResults}
            isCorrect={isCorrect}
          />
          <Footer
            messages={messages}
            formatMessage={formatMessage}
            triviaState={triviaState}
            socketData={socketData}
            connected={connected}
            data={data}
            question={question}
            username={username}
          />
        </Fragment>
      )}
    </div>
  );
};

export default Question;
