import api from './client';
import apiWithoutToken from './clientWithoutToken';
import {
  clientId as client_id,
  clientSecret as client_secret,
} from '../core/constants';
import history from '../core/history';
import { generateUUID } from '../core/helper';

export function login(email, password, returnUrl = '/') {
  api.defaults.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: '',
  };

  return api
    .post('/auth/login', { client_id, client_secret, email, password })
    .then((response) => {
      api.defaults.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${response.data.token}`,
      };

      window.localStorage.setItem('token', JSON.stringify(response.data.token));
      window.localStorage.setItem('user', JSON.stringify(response.data.user));
      history.push(returnUrl);
    });
}

export function guestLogin() {
  api.defaults.headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: '',
  };

  return api
    .post('/auth/guest', {
      client_id,
      client_secret,
      session_id: generateUUID(),
    })
    .then((response) => {
      api.defaults.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${response.data.token}`,
      };

      window.localStorage.setItem('token', JSON.stringify(response.data.token));
      window.location.reload();
    });
}

export function getTriviaInfo(id) {
  return api.get(`/trivia/${id}`);
}

export function getTriviaState(id) {
  return api.get(`/trivia/state/${id}`);
}

export function getTriviaStateWithoutToken(id) {
  return apiWithoutToken.get(`/trivia/state/${id}`);
}

export function getTriviaCredit() {
  return api.get(`/trivia-credits`);
}

export function triviaInit(id, options) {
  return api.post(`/trivia/init`, { quiz_id: id, ...options });
}

export function triviaAction(id, action) {
  return api.get(`/trivia/${action}/${id}`);
}

export function setMode(id, mode) {
  return api.get(`/trivia/mode/${id}/${mode}`);
}

export function setTier(id, tier) {
  return api.get(`/trivia/tier/${id}/${tier}`);
}

export function setRedirectUrl(id, url) {
  return api.post('/trivia/set-redirect-url', { quiz_id: id, url });
}

export function registerUser(id, data) {
  return api.post(`/trivia/register/${id}`, { ...data });
}

export function getRegisterFields(id) {
  return api.get(`/trivia/register-fields/${id}`);
}

export function getPlayerStatus(id) {
  return api.get(`/trivia/player-status/${id}`);
}

export function vote(answerId, triviaId) {
  return api.get(`/vote/${answerId}?trivia_id=${triviaId}&vote_client=7`);
}

export function getVoteCounts(questionId) {
  return api.get(`/trivia/vc/${questionId}`);
}

export function getResult(id) {
  return api.get(`/trivia/result/${id}`);
}

export function getTopPlayers(id, combinedIds) {
  if (combinedIds) {
    return api.get(`/trivia/combine-scores/${combinedIds}`);
  }
  return api.get(`/trivia/top-players/${id}`);
}

export function checkCName(url) {
  return api.post('/trivia/check-cname', { url });
}

export function getLocaleInfo() {
  return api.get(`/locale-info`);
}
