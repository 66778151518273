import React, { useEffect, useCallback, useState } from 'react';
import { injectIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import toastr from 'toastr';
import * as Sentry from '@sentry/react';

import {
  getTriviaInfo,
  getTriviaState,
  getVoteCounts,
  getTopPlayers,
} from '../../api';
import Sockette from 'sockette';
import { setBackground, getParameterByName } from '../../core/helper';
import { usePrevious } from '../../core/customHooks';

import './styleBoard.css';
import Question from './Question';
import Scores from './Scores';
import Waiting from './Question/Waiting';
import Footer from './Footer';
import messages from './messages';

import { socketURL } from '../../core/constants';

const LeaderBoard = ({ intl: { formatMessage } }) => {
  const navigate = useNavigate();
  const { triviaId, triviaIds } = useParams();
  const [state, setState] = useState({
    loading: true,
    data: {},
    triviaState: {},
    socketData: {},
    connected: false,
    showResults: false,
    isCalculating: false,
    topPlayers: [],
    hidePlayers: false,
    hideQuestions: false,
    hideAnimations: false,
    bump: true,
    isCustomUrl: false,
    customUrl: '',
    isFetching: false,
    playerCount: 10,
  });
  const [combinedId] = useState(triviaIds ? triviaIds.split(',')[0] : triviaId);
  const [combinedIds] = useState(triviaIds);
  const [qi, setQi] = useState(-1);
  const [isPrepping, setIsPrepping] = useState(false);
  const [gettingPlayers, setGettingPlayers] = useState(false);

  const prevCount = usePrevious(state.socketData.player_count);

  const connectWS = useCallback((id) => {
    const ws = new Sockette(`${socketURL}/trivia-status/${id}`, {
      timeout: 5e3,
      maxAttempts: 10,
      onopen: (e) =>
        setState((s) => ({
          ...s,
          connected: true,
          readyState: e.target.readyState,
        })),
      onmessage: (e) => {
        if (e.data !== 'ping' || e.data !== 'PING') {
          const message = JSON.parse(e.data);
          setState((s) => ({
            ...s,
            socketData: message,
            readyState: e.target.readyState,
          }));
        }
      },
      onreconnect: (e) =>
        setState((s) => ({
          ...s,
          connected: true,
          readyState: e.target.readyState,
        })),
      onmaximum: (e) => console.log('Stop Attempting!', e),
      onclose: (e) =>
        setState((s) => ({
          ...s,
          connected: true,
          readyState: e.target.readyState,
        })),
      onerror: (e) =>
        setState((s) => ({
          ...s,
          connected: true,
          readyState: e.target.readyState,
        })),
    });

    setInterval(() => {
      try {
        ws.send('ping');
      } catch (e) {
        console.log(e);
        setState((s) => ({
          ...s,
          connected: false,
        }));
      }
    }, 1000);
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getTriviaState(combinedId);

      setState((s) => ({ ...s, triviaState: data }));
      connectWS(combinedId);

      const response = await getTriviaInfo(combinedId);
      setBackground(response.data);
      setState((s) => ({
        ...s,
        loading: false,
        data: response.data,
      }));
    } catch (e) {
      if (e?.response?.status === 404) {
        toastr.info('Trivia is not active. Enter an active trivia id.');
        navigate('/board');
      } else {
        Sentry.withScope((scope) => {
          scope.setExtras(e);
          Sentry.captureException(e);
        });
        navigate(`/login?returnUrl=board&returnId=${combinedId}`);
      }
    }
  }, [navigate, combinedId, connectWS]);

  useEffect(() => {
    if (triviaId.length < 2 || isNaN(triviaId)) {
      navigate('/board');
      return;
    }

    setState((s) => ({
      ...s,
      hidePlayers: getParameterByName('hide_players') === 'true',
      hideQuestions: getParameterByName('hide_questions') === 'true',
      hideAnimations: getParameterByName('hide_animations') === 'true',
      playerCount: getParameterByName('player_count'),
    }));
    if (getParameterByName('hide_animations') === 'true') {
      document.getElementsByTagName('body')[0].classList.add('notransition');
    }

    fetchData();
  }, [fetchData, triviaId, navigate]);

  const moveToNext = (current_q) => {
    setQi(current_q - 1);
    setState((s) => ({
      ...s,
      showResults: false,
      bump: false,
    }));
  };

  const getTriviaData = useCallback(async () => {
    try {
      const { data } = await getTriviaInfo(triviaId);
      setState((s) => ({ ...s, data }));
    } catch (e) {
      window.location.reload();
    }
  }, [triviaId]);

  const getPlayersWithStatus = useCallback(
    (newPlayers) => {
      let playersWithStatus = [];
      newPlayers.forEach((newPlayer, i) => {
        const oldIndex = state.topPlayers
          .map((t) => t.player)
          .indexOf(newPlayer.player);
        const isCorrect =
          oldIndex === -1 ||
          (oldIndex > -1 && newPlayer.score > state.topPlayers[oldIndex].score);
        if (i < oldIndex || oldIndex === -1) {
          playersWithStatus.push({ ...newPlayer, direction: 'up', isCorrect });
        } else if (i > oldIndex && oldIndex !== -1) {
          playersWithStatus.push({
            ...newPlayer,
            direction: 'down',
            isCorrect,
          });
        } else {
          playersWithStatus.push({ ...newPlayer, isCorrect });
        }
      });
      return playersWithStatus;
    },
    [state.topPlayers]
  );

  const getPlayerData = useCallback(async () => {
    if (!gettingPlayers) {
      try {
        setGettingPlayers(true);
        const { data } = await getTopPlayers(triviaId, combinedIds);
        setState((s) => ({ ...s, topPlayers: getPlayersWithStatus(data) }));
        setGettingPlayers(false);
      } catch (e) {
        setGettingPlayers(false);
      }
    }
  }, [triviaId, combinedIds, getPlayersWithStatus, gettingPlayers]);

  const showResults = useCallback(async () => {
    const { data } = state;
    try {
      setState((s) => ({ ...s, isCalculating: true }));
      setIsPrepping(true);
      setTimeout(async () => {
        if (data?.questions && data.questions[qi]) {
          const response = await getVoteCounts(data.questions[qi].id);
          getPlayerData();
          setState((s) => ({
            ...s,
            bump: true,
            isCalculating: false,
            showResults: true,
            data: {
              ...s.data,
              questions: s.data.questions.map((question, i) => {
                if (i === qi) {
                  return {
                    ...question,
                    answers: question.answers.map((answer) => {
                      return {
                        ...answer,
                        vote_count: response.data.answers.filter(
                          (a) => a.answer_id === answer.id
                        )[0].vc,
                        is_right_answer: response.data.answers.filter(
                          (a) => a.answer_id === answer.id
                        )[0].is_right_answer,
                      };
                    }),
                    vote_count: response.data.vc,
                  };
                }
                return question;
              }),
            },
          }));
        } else {
          Sentry.withScope((scope) => {
            scope.setExtras({ message: 'no question', data, qi });
            Sentry.captureException(new Error('no question'));
          });
        }
        setIsPrepping(false);
      }, 5000);
    } catch (e) {
      Sentry.withScope((scope) => {
        scope.setExtras(e);
        Sentry.captureException(e);
      });
    }
  }, [getPlayerData, qi, state]);

  useEffect(() => {
    const { socketData, data } = state;

    if (!data?.questions?.length) {
      if (
        socketData.status === 'active' ||
        socketData.seconds_to_start_date < 100
      ) {
        getTriviaData();
      }
    }

    if (
      socketData.current_question !== qi + 1 &&
      socketData.status === 'active'
    ) {
      setTimeout(
        () => {
          moveToNext(socketData.current_question);
        },
        socketData.current_question === 0 ? 5000 : 1
      );
    }

    if (socketData.status === 'reset') {
      window.location.href = '/board';
    }

    if (socketData.reset_token) {
      window.localStorage.clear();
      window.location.reload();
    }

    if (!isPrepping) {
      if (socketData.timer_remaining < 4 && !state.showResults && qi > -1) {
        showResults();
      }
    }
  }, [state, getPlayerData, getTriviaData, showResults, qi, isPrepping]);

  useEffect(() => {
    if (prevCount !== state.socketData.player_count && qi < 0) {
      getPlayerData();
    }
  }, [state.socketData.player_count, getPlayerData, prevCount, qi]);

  return (
    <section id="main">
      {state.loading ? (
        <div className="card">
          <div className="card__answer">{formatMessage(messages.Loading)}</div>
        </div>
      ) : (
        <div className="leaderboard">
          <div className="leaderboard__header">
            <h1 style={{ textAlign: 'center' }}>{state.data.title}</h1>
          </div>
          <div className="leaderboard__main">
            {combinedIds || state.hideQuestions ? null : (
              <div
                className={`${state.hidePlayers ? '' : 'leaderboard__leftcol'}`}
              >
                {qi === -1 && (
                  <Waiting
                    messages={messages}
                    formatMessage={formatMessage}
                    id={state.data.id}
                    triviaState={state.triviaState}
                  />
                )}
                {qi >= 0 &&
                  !state.hideQuestions &&
                  state.data.questions.map(
                    (question, i) =>
                      qi === i && (
                        <Question
                          messages={messages}
                          formatMessage={formatMessage}
                          key={i}
                          question={question}
                          questionIndex={qi}
                          index={i}
                          showResults={state.showResults}
                          isCalculating={state.isCalculating}
                          hidePlayers={state.hidePlayers}
                        />
                      )
                  )}
              </div>
            )}
            {!state.hidePlayers && (
              <div
                className={`${
                  state.hideQuestions ? '' : 'leaderboard__rightcol'
                }`}
              >
                <Scores
                  players={state.topPlayers}
                  questionIndex={qi}
                  timerRemaining={state.socketData.timer_remaining}
                  showWinner={
                    state.socketData.current_question ===
                      state.data?.questions?.length &&
                    state.showResults &&
                    !combinedIds
                  }
                  bump={state.bump}
                  playerCount={parseInt(state.playerCount || 10, 10)}
                  combinedIds={combinedIds}
                  gettingPlayers={gettingPlayers}
                />
              </div>
            )}
          </div>
          <Footer
            messages={messages}
            formatMessage={formatMessage}
            socketData={state.socketData}
            connected={state.connected}
            data={state.data}
          />
        </div>
      )}
    </section>
  );
};

export default injectIntl(LeaderBoard);
