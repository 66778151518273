/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import { FormattedMessage } from 'react-intl.macro';
import { feedImagePathRetina, footerLogo } from '../../../core/constants';

import Board from '../Question/Board';
import './style.css';

const Result = ({
  triviaState,
  socketData,
  result,
  questionIndex,
  isPrepping,
  correctAnswers,
  username,
  showFinalBoard,
  data,
  players,
  closeBoard,
  messages,
  formatMessage,
}) => {
  return showFinalBoard && triviaState.player_leaderboard ? (
    <Board
      messages={messages}
      formatMessage={formatMessage}
      players={players}
      data={data}
      closeBoard={closeBoard}
    />
  ) : (
    <div
      id={`q${socketData.total_questions}`}
      className={`card card--countdown card--sequence
        ${
          questionIndex === socketData.total_questions
            ? 'card--sequence-active'
            : ''
        }
        ${
          questionIndex === socketData.total_questions && isPrepping
            ? 'isShiftingOut'
            : ''
        }
        ${
          questionIndex + 1 === socketData.total_questions && isPrepping
            ? 'isShiftingIn'
            : ''
        }
      `}
    >
      <div className="card__question">
        <figure className="card__q-image" id="resultImg">
          {result && result.background ? (
            <img src={`${feedImagePathRetina}/${result.background}`} alt="" />
          ) : null}
        </figure>
        <div className="card__q-title">
          <div className="card__q-title__content">
            <h3 className="h3--large" style={{ textAlign: 'center' }}>
              <span id="resultTitle">{result && result.title}</span>
              <br />
              <small id="resultDesc">{result && result.desc}</small>
            </h3>
          </div>
        </div>
      </div>
      <div className="card__answer">
        <div style={{ width: '100%' }} id="resultArea">
          <p>
            {result && result.url && (
              <button
                className="button button--primary button--large"
                onClick={() => window.open(result.url, '_blank')}
              >
                {result.url_text || formatMessage(messages.GoToSite)}
              </button>
            )}
          </p>
          <big id="correctAnswer">{` ${correctAnswers}/${socketData.total_questions} `}</big>
          {formatMessage(messages.CorrectAnswers)}
          <br />

          {triviaState.elimination ? (
            <Fragment>
              <FormattedMessage
                id="Player.Winners"
                defaultMessage={`{winners} winners`}
                values={{
                  winners: <b>{`${triviaState.winner_count} `}</b>,
                }}
              />
            </Fragment>
          ) : null}

          {username && (
            <h3>
              <span className="participant__name">
                {username.split('-')[0]}
              </span>
              <span style={{ fontWeight: 'normal', fontSize: '10px' }}>
                {' '}
                (#
                {username.split('-')[1]})
              </span>
            </h3>
          )}
        </div>
      </div>
      <div className="card__footer">
        <div>
          {data.embed_footer_img ? (
            <a
              href=""
              onClick={(e) => e.preventDefault()}
              style={{ display: 'block', padding: '0.675rem 0.75rem 0rem' }}
            >
              <img
                src={`${footerLogo}/${data.embed_footer_img}`}
                alt=""
                style={{ height: '1.5rem' }}
              />
            </a>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Result;
