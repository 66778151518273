import React from 'react';

const Countdown = ({
  secRemaining,
  index,
  showTimer,
  timerValue,
  alertThreshold,
  duration,
}) => (
  <div className="card__answer" style={{ height: '0', paddingBottom: '0' }}>
    <div className={`countdown ${showTimer ? '' : 'isComplete'}`}>
      <div className="countdown__track" />
      <div
        className={`countdown__pie ${timerValue > 180 ? 'isFull' : ''} ${
          timerValue > (360 * alertThreshold) / duration ? 'isAlert' : ''
        }`}
        id={`cover_pie${index}`}
      >
        <div
          className="countdown__spinner countdown__slice"
          id={`cover_spinner${index}`}
          style={{
            OTransform: `rotate(${timerValue}deg)`,
            MozTransform: `rotate(${timerValue}deg)`,
            msTransform: `rotate(${timerValue}deg)`,
            transform: `rotate(${timerValue}deg)`,
          }}
        />
        <div className="countdown_filler countdown__slice" />
      </div>
      <div className="countdown__content">
        <span className="value">{secRemaining}</span>
      </div>
    </div>
  </div>
);

export default Countdown;
