/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { FormattedMessage } from 'react-intl.macro';
import { getLocaleInfo } from '../../../api';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

import { feedImagePathRetina, footerLogo } from '../../../core/constants';
import './style.css';
import people from '../../../images/icons/people.svg';

const Cover = ({
  messages,
  formatMessage,
  data,
  socketData,
  connected,
  username,
  index,
  questionIndex,
  isPrepping,
  errorMessage,
  onFieldChange,
  errorFields,
  registerFields,
  registerUser,
  moveToNext,
}) => {
  const duration = 3000;
  const [cdTimer, setCdTimer] = useState(null);
  const [countStarted, setCountStarted] = useState(false);
  const [country, setCountry] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [secRemaining, setSecRemaining] = useState(duration / 1000);
  const [timerValue, setTimerValue] = useState(0);

  useEffect(() => {
    const getLocale = async () => {
      const { data } = await getLocaleInfo();
      setCountry(data['cf-header'] || data['geo-ip']);
    };
    getLocale();
  }, []);

  useEffect(() => {
    if (socketData.status === 'ready') {
      setStatusMessage(formatMessage(messages.Accepting));
    } else if (socketData.status === 'active') {
      setStatusMessage(formatMessage(messages.TriviaActive));
    } else {
      setStatusMessage(formatMessage(messages.TriviaWaiting));
    }
  }, [socketData.status, formatMessage, messages]);

  useEffect(() => {
    if (cdTimer || !countStarted) return;

    const id = setInterval(() => {
      setTimerValue((tv) => (tv > 359 ? 360 : tv + 36));
    }, 300);
    setCdTimer(id);
    return () => clearInterval(cdTimer);
  }, [countStarted, cdTimer]);

  useEffect(() => {
    const floatRemaining = 1 - timerValue / 360;
    const sRemaining = Math.ceil(floatRemaining * (duration / 1000));
    setSecRemaining(sRemaining);

    if (!countStarted && socketData.status === 'active') {
      setCountStarted(true);
    }
  }, [data, timerValue, socketData.status, countStarted]);

  useEffect(() => {
    if (timerValue === 360 && socketData.current_question < 2) {
      moveToNext(1, true);
    }
  }, [timerValue, moveToNext, socketData]);

  const onFormSubmit = async (e) => {
    e.preventDefault();
    registerUser();
  };

  const countdownRenderer = (seconds) => {
    if (seconds < 1) {
      return null;
    } else {
      let second = parseInt(seconds, 10);
      let days = Math.floor(second / (3600 * 24));
      second -= days * 3600 * 24;
      let hrs = Math.floor(second / 3600);
      second -= hrs * 3600;
      let mnts = Math.floor(second / 60);
      second -= mnts * 60;

      return (
        <p>
          <FormattedMessage
            id="Player.TriviaStarts"
            defaultMessage={`Trivia starts in
            {days, plural, =0 {} one {{days} day} other {{days} days}}
            {hrs, plural, =0 {} one {{hrs} hour} other {{hrs} hours}}
            {mnts, plural, =0 {{mnts} minutes} one {{mnts} minute} other {{mnts} minutes}}
            {second, plural, =0 {{second} seconds} one {{second} second} other {{second} seconds}}`}
            values={{
              days: days,
              hrs: hrs,
              mnts: mnts,
              second: second,
            }}
          />
        </p>
      );
    }
  };

  return (
    <div
      id={`q${index}`}
      className={`card card--countdown card--sequence
        ${questionIndex === index ? 'card--sequence-active' : ''}
        ${questionIndex === index && isPrepping ? 'isShiftingOut' : ''}
        ${questionIndex + 1 === index && isPrepping ? 'isShiftingIn' : ''}
      `}
    >
      <div className="card__question" style={{ maxHeight: '16rem' }}>
        <figure className="card__q-image">
          {data && data.background ? (
            <img src={`${feedImagePathRetina}/${data.background}`} alt="" />
          ) : null}
        </figure>
        <div className="card__q-title">
          <div className="card__q-title__content">
            <h3 className="h3--large" style={{ textAlign: 'center' }}>
              {data.title}
            </h3>
          </div>
        </div>
      </div>

      <div className="card__answer">
        <div style={{ width: '100%' }}>
          {countdownRenderer(socketData.seconds_to_start_date)}
          <p>{data.desc}</p>

          {(!socketData.status || socketData.status === 'reset') && (
            <div
              className="response"
              style={{
                width: '100%',
                marginTop: '0.5rem',
                height: '1.25rem',
              }}
            >
              This page will reload when ready
            </div>
          )}

          {(socketData.status === 'ready' ||
            socketData.status === 'active') && (
            <Fragment>
              {username ? (
                <div
                  id="participant"
                  className="response"
                  style={{
                    width: '100%',
                    marginTop: '0.5rem',
                    clear: 'both',
                  }}
                >
                  <p>{formatMessage(messages.ParticipatingAs)}</p>
                  <h3>
                    <span className="participant__name">
                      {username.split('-')[0]}
                    </span>
                    <span style={{ fontWeight: 'normal', fontSize: '10px' }}>
                      {' '}
                      (#
                      {username.split('-')[1]})
                    </span>
                  </h3>
                </div>
              ) : (
                <form
                  id="form"
                  onSubmit={onFormSubmit}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    marginBottom: '1rem',
                  }}
                >
                  <p>{formatMessage(messages.EnterYourName)}</p>
                  {registerFields &&
                    registerFields.length > 0 &&
                    registerFields.map((field) => {
                      if (field.name === 'phone') {
                        return (
                          <div key={field.name} style={{ width: '100%' }}>
                            <PhoneInput
                              country={country || 'TR'}
                              placeholder={field.label}
                              onChange={(phone) =>
                                onFieldChange({
                                  target: { name: 'phone', value: phone },
                                })
                              }
                              className="field field--large"
                              style={{
                                marginBottom: '0.5rem',
                                borderColor:
                                  errorFields === field.name ? 'red' : '#ccc',
                              }}
                            />
                          </div>
                        );
                      }
                      return (
                        <div key={field.name} style={{ width: '100%' }}>
                          <input
                            name={field.name}
                            className={
                              field.type === 'checkbox'
                                ? 'field--checkbox'
                                : 'field field--large'
                            }
                            type={field.type}
                            placeholder={field.label}
                            style={{
                              marginBottom: '0.5rem',
                              borderColor:
                                errorFields === field.name ? 'red' : '#ccc',
                            }}
                            onChange={onFieldChange}
                          />
                          {field.type === 'checkbox' ? (
                            <label>
                              <ReactMarkdown
                                children={field.label}
                                linkTarget="_blank"
                              />
                            </label>
                          ) : null}
                        </div>
                      );
                    })}
                  <br />
                  <button
                    type="submit"
                    className="button button--primary button--large"
                    style={{ marginLeft: '0.5rem', marginBottom: '0.5rem' }}
                  >
                    {formatMessage(messages.Join)}
                  </button>
                  <div
                    className="response"
                    style={{
                      width: '100%',
                      marginTop: '0.5rem',
                      height: '1.25rem',
                    }}
                  >
                    {errorMessage}
                  </div>
                </form>
              )}
            </Fragment>
          )}

          {socketData.current_question < 2 &&
            socketData.status === 'active' &&
            username &&
            countStarted && (
              <div id="countdown" className="countdown countdown--cover">
                <div className="countdown__track" />
                <div
                  className={`countdown__pie isAlert ${
                    timerValue > 180 ? 'isFull' : ''
                  }`}
                  id="cover_pie"
                >
                  <div
                    className="countdown__spinner countdown__slice"
                    id="cover_spinner"
                    style={{
                      transition: '0.3s linear',
                      OTransform: `rotate(${timerValue}deg)`,
                      MozTransform: `rotate(${timerValue}deg)`,
                      msTransform: `rotate(${timerValue}deg)`,
                      transform: `rotate(${timerValue}deg)`,
                    }}
                  />
                  <div className="countdown_filler countdown__slice" />
                </div>
                <div className="countdown__content">
                  <span className="value">{secRemaining}</span>
                </div>
              </div>
            )}

          {socketData.status && socketData.status !== 'reset' && (
            <div className="badge badge--status">
              <img src={people} alt="" style={{ width: '1rem' }} />
              <span>
                <b className="value">{socketData.player_count}</b>{' '}
                {formatMessage(messages.PeopleParticipating)}
              </span>
            </div>
          )}
        </div>
      </div>

      <div className="card__footer">
        <div>
          {data.embed_footer_img ? (
            <a
              href=""
              onClick={(e) => e.preventDefault()}
              style={{ display: 'block', padding: '0.675rem 0.75rem 0rem' }}
            >
              <img
                src={`${footerLogo}/${data.embed_footer_img}`}
                alt=""
                style={{ height: '1.5rem' }}
              />
            </a>
          ) : null}
        </div>
        <div style={{ lineHeight: '2rem', opacity: '0.5' }} id="round_status">
          <span className="hasTooltip">
            {connected ? (
              <Fragment>
                <div
                  className="dot-green"
                  style={{ marginRight: '15px', marginTop: '10px' }}
                />
                <span className="tooltip">
                  {formatMessage(messages.Online)}
                </span>
              </Fragment>
            ) : (
              <Fragment>
                <div
                  className="dot-red"
                  style={{ marginRight: '15px', marginTop: '10px' }}
                />
                <span className="tooltip">
                  {formatMessage(messages.Reconnecting)}
                </span>
              </Fragment>
            )}
          </span>
          {statusMessage}
        </div>
      </div>
    </div>
  );
};

export default Cover;
