import React from 'react';
import { feedImagePathRetina } from '../../../core/constants';

const Image = ({ question, isCalculating, messages, formatMessage }) => (
  <div
    className={`card__question${
      isCalculating ? ' isActive isCalculating' : ''
    }`}
  >
    <figure className="card__q-image">
      {question.background ? (
        <img src={`${feedImagePathRetina}/${question.background}`} alt="" />
      ) : (
        <img src={`${feedImagePathRetina}/placeholder/question/1.png`} alt="" />
      )}
    </figure>
    <div className="card__q-title">
      <div className="card__q-title__content">
        <h3
          className={`h3${
            question && question.title && question.title.length > 100
              ? ''
              : '--large'
          }`}
        >
          {question.title}
        </h3>
      </div>
    </div>
    <div className="card__q--calculatingresult">
      <span className="progress">
        <span className="progress-bar" />
      </span>
      <strong className="text">{formatMessage(messages.Calculating)}</strong>
    </div>
  </div>
);

export default Image;
