/** @jsxRuntime classic */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './core/localStoragePolyfill.js';
import 'url-search-params-polyfill';

import React from 'react';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';

import { sentryConfig } from './core/sentryConfig.js';
import './styles/style.css';
import App from './components/App.jsx';
import history from './core/history.js';

import 'animate.css';

import translations from './i18n/locales/index.js';

Sentry.init(sentryConfig);

const search = history.location.search;
const params = new URLSearchParams(search); // eslint-disable-line no-undef
const loc = params.get('loc');
const locParam = ['en', 'tr'].indexOf(loc) > -1 ? loc : null;

const language =
  locParam ||
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;
const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];
const messages =
  translations[languageWithoutRegionCode] ||
  translations[language] ||
  translations.en;

document.documentElement.setAttribute('lang', languageWithoutRegionCode);

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <IntlProvider
    locale={languageWithoutRegionCode}
    key={language}
    messages={messages}
  >
    <App />
  </IntlProvider>
);
