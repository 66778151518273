import { cdnPath } from './constants';

export const setBackground = (data) => {
  if (data.embed_background) {
    document.body.style.backgroundImage = `url('${cdnPath}/0x0/${data.embed_background}')`;
    document.body.style.backgroundSize = 'cover';
    document.body.style.position = 'center';
  } else {
    document.body.style.backgroundImage = `url('/images/poltio-logo-texture.svg')`;
    document.body.style.backgroundAttachment = 'fixed';
    document.body.style.backgroundSize = '100px';
  }
};

export function generateUUID() {
  let d = new Date().getTime();
  if (window.performance && typeof window.performance.now === 'function') {
    d += window.performance.now();
  }
  const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === 'x' ? r : r & (0x3 | 0x8)).toString(16);
  });
  return uuid;
}

export const calculatePercentage = (total, option) => (option / total) * 100;

export const getParameterByName = (name, url) => {
  if (!url) {
    url = window.location.href;
  }
  // eslint-disable-next-line
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const setManagerMessage = (playerCount, tier) => {
  let message = null;
  if (tier === 'free' && playerCount < 5) {
    message =
      'Trivia is ready and accepting players, you can start anytime you want.';
  } else if (tier === 'free' && playerCount === 5) {
    message =
      'You have reached the player limit, you can start anytime you want.';
  } else if (tier === 'credit' && playerCount <= 5) {
    message =
      'You will be charged with 0 credit, you can start anytime you want.';
  } else if (tier === 'credit' && playerCount > 5 && playerCount <= 50) {
    message =
      'You will be charged with 1 credit, you can start anytime you want.';
  } else if (tier === 'credit' && playerCount > 50 && playerCount <= 500) {
    message =
      'You will be charged with 2 credits, you can start anytime you want.';
  } else if (tier === 'credit' && playerCount > 500 && playerCount <= 2000) {
    message =
      'You will be charged with 4 credits, you can start anytime you want.';
  } else if (tier === 'credit' && playerCount > 2000) {
    message = 'This is an enterprise edition, you can start anytime you want.';
  }
  return message;
};

export const validateEmail = (email) => {
  // eslint-disable-next-line
  const re =
    /(([A-Za-z0-9]+_+)|([A-Za-z0-9]+-+)|([A-Za-z0-9]+\.+)|([A-Za-z0-9]+\++))*[A-Za-z0-9]+@((\w+-+)|(\w+\.))*\w{1,63}\.[a-zA-Z0-9]{2,6}/;
  return re.test(email);
};

export const validateGsm = (gsm) => {
  // eslint-disable-next-line
  const phoneRe = /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/;
  return phoneRe.test(gsm);
};
