/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import { footerLogo } from '../../../core/constants';
import people from '../../../images/icons/people.svg';
import logo2 from '../../../images/poltio-logo-monogram.svg';

const Footer = ({
  triviaState = {},
  socketData = {},
  connected,
  data = {},
  question = {},
  username,
  messages,
  formatMessage,
}) => (
  <div className="card__footer">
    <div>
      {data.embed_footer_img ? (
        <a
          href=""
          onClick={(e) => e.preventDefault()}
          style={{ display: 'block', padding: '0.675rem 0.75rem 0rem' }}
        >
          <img
            src={`${footerLogo}/${data.embed_footer_img}`}
            alt=""
            style={{ height: '1.5rem' }}
          />
        </a>
      ) : null}
    </div>
    <div
      style={{ lineHeight: '2rem', color: 'red', fontWeight: 'bold' }}
      id={`round_status${question.id}`}
    >
      {username
        ? triviaState.elimination && triviaState.eliminated
          ? formatMessage(messages.Eliminated)
          : triviaState.can_play
          ? null
          : formatMessage(messages.ViewerMode)
        : formatMessage(messages.ViewerMode)}
    </div>
    <div>
      <span className="hasTooltip">
        {connected ? (
          <Fragment>
            <div className="dot-green" style={{ marginRight: '10px' }} />
            <span className="tooltip">{formatMessage(messages.Online)}</span>
          </Fragment>
        ) : (
          <Fragment>
            <div className="dot-red" style={{ marginRight: '10px' }} />
            <span className="tooltip">
              {formatMessage(messages.Reconnecting)}
            </span>
          </Fragment>
        )}
      </span>
      <span className="hasTooltip">
        <a href="" onClick={(e) => e.preventDefault()} className="link--icon">
          <img src={people} alt="" />
          <span className="value">{socketData.player_count}</span>
        </a>
        <span className="tooltip">
          {`${socketData.player_count} ${formatMessage(
            messages.PeopleLoggedIn
          )}`}
        </span>
      </span>
      <span className="hasTooltip">
        <a
          href=""
          style={{
            display: 'inline-block',
            float: 'right',
            opacity: '1',
            marginRight: '-0.5rem',
          }}
          onClick={(e) => e.preventDefault()}
        >
          <img src={logo2} alt="" style={{ height: '2rem' }} />
        </a>
        <span className="tooltip" style={{ right: '-1rem' }}>
          Powered by Poltio
        </span>
      </span>
    </div>
  </div>
);

export default Footer;
