import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  GoToLeaderboard: {
    id: 'Board.GoToLeaderboard',
    defaultMessage: 'Go To Leaderboard',
  },
  EnterTriviaCode: {
    id: 'Board.EnterTriviaCode',
    defaultMessage: 'Enter Trivia Code',
  },
  Loading: {
    id: 'Board.Loading',
    defaultMessage: 'Loading',
  },
  JoinQuiz: {
    id: 'Board.JoinQuiz',
    defaultMessage: 'Join the quiz at',
  },
  UseCode: {
    id: 'Board.UseCode',
    defaultMessage: 'and use the code:',
  },
  WaitingModerator: {
    id: 'Board.WaitingModerator',
    defaultMessage: 'Waiting for moderator',
  },
  TotalFunds: {
    id: 'Board.TotalFunds',
    defaultMessage: 'Total funds raised:',
  },
  CalculatingResult: {
    id: 'Board.CalculatingResult',
    defaultMessage: 'Calculating result...',
  },
  Question: {
    id: 'Board.Question',
    defaultMessage: 'Question',
  },
  PeopleParticipating: {
    id: 'Board.PeopleParticipating',
    defaultMessage: 'people participating',
  },
  Online: {
    id: 'Board.Online',
    defaultMessage: 'Online',
  },
  Reconnecting: {
    id: 'Board.Reconnecting',
    defaultMessage: 'Trying to reconnect...',
  },
});
