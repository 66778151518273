import React, { useState, useEffect, useCallback } from 'react';
import { injectIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import toastr from 'toastr';
import {
  getTriviaInfo,
  getTriviaState,
  registerUser,
  getPlayerStatus,
  guestLogin,
  getResult,
  getTopPlayers,
  getRegisterFields,
} from '../../api';
import Sockette from 'sockette';
import { setBackground, validateEmail } from '../../core/helper';
import * as Sentry from '@sentry/react';
import { socketURL } from '../../core/constants';
import './stylePlayer.css';

import messages from './messages';
import Cover from './Cover';
import Question from './Question';
import Result from './Result';

toastr.options.preventDuplicates = true;

const Player = ({ intl: { formatMessage } }) => {
  const navigate = useNavigate();
  const { triviaId } = useParams();
  const [state, setState] = useState({
    loading: true,
    data: {},
    triviaState: {},
    socketData: {},
    connected: false,
    readyState: 0,
    user: {},
    username: null,
    isFetching: false,
    errorMessage: null,
    customFields: [],
  });
  const [qi, setQi] = useState(-1);
  const [isPrepping, setIsPrepping] = useState(false);
  const [showingResult, setShowingResult] = useState(false);
  const [topPlayers, setTopPlayers] = useState([]);
  const [showFinalBoard, setShowFinalBoard] = useState(false);
  const [result, setResult] = useState({});
  const [correctAnswers, setCorrectAnswers] = useState(0);

  const checkCredentials = useCallback(
    (mode) => {
      if (triviaId.length < 2 || isNaN(triviaId)) {
        navigate('/');
        return;
      }

      let user = null;
      user = JSON.parse(window.localStorage.getItem('user'));

      let token = null;
      token = JSON.parse(window.localStorage.getItem('token'));

      if (!user && mode === 'login') {
        navigate(`/login?returnUrl=player&returnId=${triviaId}`);
        toastr.error('You must login to play the trivia!');
        return;
      }

      setState((s) => ({ ...s, user }));

      if (!token) {
        guestLogin();
      }
    },
    [navigate, triviaId]
  );

  const moveToNext = useCallback(
    (current_q, isPrep) => {
      if (state.username) {
        isPrep && setIsPrepping(true);
        setTimeout(() => {
          setQi(current_q - 1);
          isPrep && setIsPrepping(false);
        }, 600);
      }
    },
    [state]
  );

  const showResult = useCallback(async () => {
    setShowingResult(true);

    const response = await getResult(triviaId);
    const responseState = await getTriviaState(triviaId);

    setState((s) => ({
      ...s,
      triviaState: responseState.data,
    }));
    setCorrectAnswers(response.data.right_answer);
    setResult(response.data.result);

    setTimeout(() => {
      moveToNext(state.socketData.total_questions + 1);
    }, 5000);

    setTimeout(async () => {
      const response2 = await getTopPlayers(triviaId);
      setTopPlayers(response2.data);
      setShowFinalBoard(true);
    }, 15000);
  }, [setResult, moveToNext, triviaId, state.socketData.total_questions]);

  const connectWS = useCallback((id) => {
    const ws = new Sockette(`${socketURL}/trivia-status/${id}`, {
      timeout: 5e3,
      maxAttempts: 10,
      onopen: (e) =>
        setState((s) => ({
          ...s,
          connected: true,
          readyState: e.target.readyState,
        })),
      onmessage: (e) => {
        if (e.data !== 'ping' || e.data !== 'PING') {
          const message = JSON.parse(e.data);
          setState((s) => ({
            ...s,
            socketData: message,
            readyState: e.target.readyState,
          }));
        }
      },
      onreconnect: (e) =>
        setState((s) => ({
          ...s,
          connected: true,
          readyState: e.target.readyState,
        })),
      onmaximum: (e) => console.log('Stop Attempting!', e),
      onclose: (e) =>
        setState((s) => ({
          ...s,
          connected: true,
          readyState: e.target.readyState,
        })),
      onerror: (e) =>
        setState((s) => ({
          ...s,
          connected: true,
          readyState: e.target.readyState,
        })),
    });

    setInterval(() => {
      try {
        ws.send('ping');
      } catch (e) {
        console.log(e);
        setState((s) => ({
          ...s,
          connected: false,
        }));
      }
    }, 1000);
  }, []);

  const fetchData = useCallback(async () => {
    try {
      const { data } = await getTriviaState(triviaId);
      checkCredentials(data.mode);

      setState((s) => ({ ...s, triviaState: data }));
      connectWS(triviaId);

      const responseTrivia = await getTriviaInfo(triviaId);
      const responsePlayer = await getPlayerStatus(triviaId);
      const responseFields = await getRegisterFields(triviaId);
      setBackground(responseTrivia.data);
      setState((s) => ({
        ...s,
        loading: false,
        data: responseTrivia.data,
        username: responsePlayer.data.username,
        fields: responseFields.data,
      }));
    } catch (e) {
      if (e?.response?.status === 404) {
        toastr.error('Trivia is not active. Enter an active trivia id.');
        navigate('/');
      } else {
        Sentry.withScope((scope) => {
          scope.setExtras(e);
          Sentry.captureException(e);
        });
        navigate(`/login?returnUrl=player&returnId=${triviaId}`);
      }
    }
  }, [navigate, triviaId, checkCredentials, connectWS]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getQuestions = useCallback(async () => {
    try {
      const responseTrivia = await getTriviaInfo(triviaId);
      setState((s) => ({ ...s, data: responseTrivia.data }));
    } catch (e) {
      window.location.reload();
    }
  }, [triviaId]);

  useEffect(() => {
    const { data, socketData } = state;

    if (socketData.status === 'reset') {
      window.location.href = '/';
    }
    if (socketData.reset_token) {
      window.localStorage.clear();
      window.location.reload();
    }
    if (socketData.url?.length > 0) {
      window.open(socketData.url, '_self');
    }
    if (
      socketData.status === 'active' &&
      !isPrepping &&
      socketData.current_question > qi + 1
    ) {
      if (socketData.current_question > 1) {
        moveToNext(socketData.current_question, qi !== -1);
      }
    }
    if (
      qi === socketData.total_questions - 1 &&
      socketData.timer_remaining === 0 &&
      !showingResult
    ) {
      showResult();
    }
    if (data.questions?.length === 0) {
      if (
        socketData.status === 'active' ||
        socketData.seconds_to_start_date < 100
      ) {
        getQuestions();
      }
    }
  }, [
    state,
    moveToNext,
    showResult,
    getQuestions,
    isPrepping,
    qi,
    showingResult,
  ]);

  const getEliminatedStatus = async () => {
    setState({ ...state, isFetching: true });
    try {
      const responseState = await getTriviaState(state.triviaState.id);
      setState({
        ...state,
        triviaState: responseState.data,
        isFetching: false,
      });
    } catch (e) {
      setState({ ...state, isFetching: false });
      try {
        setState({ ...state, isFetching: true });
        const responseState = await getTriviaState(state.triviaState.id);
        setState({
          ...state,
          triviaState: responseState.data,
          isFetching: false,
        });
      } catch (e) {
        setState({ ...state, isFetching: false });
      }
    }
  };

  const onFieldChange = (e) => {
    const { name, type, checked, value } = e.target;
    setState({
      ...state,
      errorMessage: null,
      errorFields: '',
      customFields: {
        ...state.customFields,
        [name]: type === 'checkbox' ? checked : value,
      },
    });
  };

  const registerUserFunc = async () => {
    const {
      customFields,
      fields,
      triviaState: { id },
    } = state;
    setState({ ...state, isFetching: true });

    try {
      let response;
      if (fields && fields.length > 0) {
        if (validateFields()) {
          response = await registerUser(id, { ...customFields });
        } else {
          return;
        }
      } else {
        response = await registerUser(id, {});
      }
      const responseState = await getTriviaState(id);
      setState({
        ...state,
        username: response.data.username,
        triviaState: responseState.data,
        isFetching: false,
      });
    } catch (e) {
      setState({ ...state, isFetching: false });
      if (e?.response?.status === 400) {
        window.location.reload();
      } else if (e?.response?.status === 403) {
        setState({
          ...state,
          errorMessage: 'Trivia is no longer accepting new players!',
        });
      } else {
        setState({ ...state, errorMessage: 'Please try different username!' });
      }
    }
  };

  const validateFields = () => {
    const { customFields, fields } = state;

    const usernameExists =
      fields.filter((r) => r.name === 'username').length > 0;
    const emailExists =
      fields.filter((r) => r.name === 'email' && r.is_optional === 0).length >
      0;
    const phoneExists =
      fields.filter((r) => r.name === 'phone' && r.is_optional === 0).length >
      0;
    const commentExists =
      fields.filter((r) => r.name === 'comment' && r.is_optional === 0).length >
      0;
    const tcExists =
      fields.filter((r) => r.name === 'tc' && r.is_optional === 0).length > 0;

    if (usernameExists) {
      if (!(customFields.username && customFields.username.length > 2)) {
        setState({
          ...state,
          errorFields: 'username',
          errorMessage: formatMessage(messages.ValidUsername),
        });
        return false;
      }
    }

    if (emailExists) {
      if (!(customFields.email && validateEmail(customFields.email))) {
        setState({
          ...state,
          errorFields: 'email',
          errorMessage: formatMessage(messages.ValidEmail),
        });
        return false;
      }
    }

    if (phoneExists) {
      if (!customFields.phone) {
        setState({
          ...state,
          errorFields: 'phone',
          errorMessage: formatMessage(messages.ValidPhone),
        });
        return false;
      }
    }

    if (commentExists) {
      if (!customFields.comment || customFields.comment.length < 1) {
        setState({
          ...state,
          errorFields: 'comment',
          errorMessage: formatMessage(messages.ValidComment),
        });
        return false;
      }
    }

    if (tcExists && !customFields.tc) {
      setState({
        ...state,
        errorFields: 'tc',
        errorMessage: formatMessage(messages.ValidTc),
      });
      return false;
    }

    return true;
  };

  const closeFinalBoard = (e) => {
    e.preventDefault();
    setShowFinalBoard(false);
  };

  return (
    <section id="main">
      {state.loading ? (
        <div className="card">
          <div className="card__answer">{formatMessage(messages.Loading)}</div>
        </div>
      ) : (
        <div className="sequence-container-wrapper">
          <div className="sequence-container">
            {qi === -1 && (
              <Cover
                messages={messages}
                formatMessage={formatMessage}
                data={state.data}
                triviaState={state.triviaState}
                socketData={state.socketData}
                connected={state.connected}
                username={state.username}
                registerUser={registerUserFunc}
                index={-1}
                questionIndex={qi}
                isPrepping={isPrepping}
                errorMessage={state.errorMessage}
                onFieldChange={onFieldChange}
                errorFields={state.errorFields}
                registerFields={state.fields}
                moveToNext={moveToNext}
              />
            )}
            {state.data?.questions?.map((question, i) => (
              <Question
                key={i}
                messages={messages}
                formatMessage={formatMessage}
                question={question}
                data={state.data}
                index={i}
                questionIndex={qi}
                isPrepping={isPrepping}
                isFetching={state.isFetching}
                triviaState={state.triviaState}
                socketData={state.socketData}
                connected={state.connected}
                showResult={showResult}
                getEliminatedStatus={getEliminatedStatus}
                username={state.username}
              />
            ))}
            <Result
              messages={messages}
              formatMessage={formatMessage}
              data={state.data}
              result={result}
              triviaState={state.triviaState}
              socketData={state.socketData}
              connected={state.connected}
              questionIndex={qi}
              isPrepping={isPrepping}
              correctAnswers={correctAnswers}
              username={state.username}
              showFinalBoard={showFinalBoard}
              players={topPlayers}
              closeBoard={closeFinalBoard}
            />
          </div>
        </div>
      )}
    </section>
  );
};

export default injectIntl(Player);
