import React, { Fragment } from 'react';

const Followup = ({
  messages,
  formatMessage,
  progressBar,
  index,
  triviaState,
  socketData,
  showResults,
  isCorrect,
}) => (
  <Fragment>
    <div className="card__followup">
      <div
        className={`card__followup-overlay ${showResults ? 'isVisible' : ''}`}
      >
        <div className="card__followup--content">
          <div className="card__followup--content--left">
            {triviaState.can_play ? (
              <Fragment>
                {triviaState.elimination && !triviaState.eliminated ? (
                  isCorrect ? (
                    <div
                      className={`text--correct ${
                        showResults ? 'isVisible' : ''
                      }`}
                    >
                      <b>{formatMessage(messages.Correct)}</b>
                    </div>
                  ) : (
                    <div
                      className={`text--wrong ${
                        showResults ? 'isVisible' : ''
                      }`}
                    >
                      <b>{formatMessage(messages.Wrong)}</b>
                    </div>
                  )
                ) : null}
                {!triviaState.elimination ? (
                  isCorrect ? (
                    <div
                      className={`text--correct ${
                        showResults ? 'isVisible' : ''
                      }`}
                    >
                      <b>{formatMessage(messages.Correct)}</b>
                    </div>
                  ) : (
                    <div
                      className={`text--wrong ${
                        showResults ? 'isVisible' : ''
                      }`}
                    >
                      <b>{formatMessage(messages.Wrong)}</b>
                    </div>
                  )
                ) : null}
              </Fragment>
            ) : null}
          </div>
          <div className="card__followup--actions">
            <small className="text--light">
              {formatMessage(messages.StayTuned)}
            </small>
          </div>
        </div>
      </div>
    </div>
    <div className="sequence__progress hasTooltip">
      <div
        className="sequence__progress-bar sequence__progress-bar"
        style={{ width: progressBar }}
      />
      <div
        className="tooltip tooltip--onLeft tooltip--onBottom"
        style={{ marginLeft: '0.5rem', marginTop: '1rem' }}
      >
        {`${formatMessage(messages.Question)} ${index + 1}/${
          socketData.total_questions
        }`}
      </div>
    </div>
  </Fragment>
);

export default Followup;
