import axios from 'axios';
import { apiURL } from '../core/constants';

const api = axios.create({
  baseURL: apiURL,
});

const apiWithoutToken = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

try {
  let token = null;
  token = JSON.parse(localStorage.getItem('token'));
  if (token) {
    api.defaults.headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  } else {
    localStorage.removeItem('token');
    api.defaults.headers = apiWithoutToken;
  }
} catch (e) {
  api.defaults.headers = apiWithoutToken;
}

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      window.localStorage.removeItem('user');
      window.localStorage.removeItem('token');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default api;
