export const apiURL = import.meta.env.VITE_API_URL;
export const socketURL = import.meta.env.VITE_SOCKET_URL;

export const clientId = 7;
export const clientSecret = 'dUx8bcOdatoswA64WpLZCcPQ53aqnVXAkGe3Tz';

export const cdnPath = `//cdn0.poltio.com`;

export const feedImagePathRetina = `${cdnPath}/1600x748`;
export const feedAnswerImagePathRetina = `${cdnPath}/800x800`;
export const feedAvatarPathRetina = `${cdnPath}/80x80`;
export const footerLogo = `${cdnPath}/160x80`;

export const defaultUrls = [
  'localhost',
  'localhost:3000',
  'http://localhost:3000',
  'http://trivia.local:3000',
  'https://trivia.poltio.com',
  'http://trivia.poltio.com',
  'http://trivia.local',
  'https://trivia-stage.poltio.com',
  'https://trivia2.poltio.com',
  'trivia.poltio.com',
  'trivia-stage.poltio.com',
];

export const setBoardFreq = (value) => {
  let count = 0;
  while (value > 1) {
    value = Math.ceil(value / 2);
    count++;
  }
  return count;
};
