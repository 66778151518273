import React from 'react';
import { feedImagePathRetina } from '../../../core/constants';
import { calculatePercentage } from '../../../core/helper';

const Question = ({
  messages,
  formatMessage,
  question,
  questionIndex,
  index,
  showResults,
  isCalculating,
}) => (
  <div
    id="q0"
    className={`card card--question ${
      questionIndex === index && !showResults ? 'isActive' : ''
    } 
    ${isCalculating ? '' : showResults ? 'isAnswered' : 'isAnswering'}
    ${isCalculating ? 'isCalculating' : ''}`}
    style={{ margin: 'auto' }}
  >
    <div className="card__question">
      <figure className="card__q-image">
        {question.background ? (
          <img src={`${feedImagePathRetina}/${question.background}`} alt="" />
        ) : (
          <img
            src={`${feedImagePathRetina}/placeholder/question/1.png`}
            alt=""
          />
        )}
      </figure>
      <div className="card__q-title">
        <div className="card__q-title__content">
          <h3 className="h3--large">{question.title}</h3>
        </div>
      </div>
      <div className="card__q--calculatingresult">
        <span className="progress">
          <span className="progress-bar" />
        </span>
        <strong className="text">
          {formatMessage(messages.CalculatingResult)}
        </strong>
      </div>
    </div>
    <div className="card__answer card__answer--mctext">
      {question.answers.map((answer, i) => (
        <button
          className={`button button--answer ${
            showResults && answer.is_right_answer ? 'isSelected isCorrect' : ''
          }`}
          key={i}
        >
          <span className="button__text">{answer.title}</span>
          <span className="button__stat">
            <span
              style={{
                width: `${calculatePercentage(
                  question.vote_count || 1,
                  answer.vote_count
                )}%`,
              }}
            />
            {showResults ? (
              <b>
                {calculatePercentage(
                  question.vote_count || 1,
                  answer.vote_count
                ).toFixed(1)}
                %
              </b>
            ) : null}
          </span>
        </button>
      ))}
    </div>
  </div>
);

export default Question;
