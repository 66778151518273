import React from 'react';

import BoardParticipantRow from '../BoardParticipantRow';

const Scores = ({
  players,
  questionIndex,
  timerRemaining,
  showWinner,
  bump,
  playerCount,
  combinedIds,
  gettingPlayers,
}) => {
  return (
    <div
      id="score"
      className={`card card--score ${bump ? 'isActive' : ''}`}
      style={{ marginLeft: 'auto', marginRight: 'auto' }}
    >
      <ol className="card__leaderboard">
        {players.map((player, i) => {
          const [userId, username] = player?.player?.split('-') || [null, ''];

          if (i < playerCount)
            return (
              <li
                key={i}
                className={`${
                  questionIndex > -1 && timerRemaining === 0
                    ? player.direction === 'up'
                      ? 'promote'
                      : player.direction === 'down'
                      ? 'demote'
                      : ''
                    : ''
                }
                ${
                  !combinedIds && questionIndex > -1 && bump && !gettingPlayers
                    ? player.isCorrect
                      ? 'isCorrect'
                      : 'isWrong'
                    : ''
                }
                ${showWinner && i === 0 ? 'isVictorious' : ''}`}
              >
                <BoardParticipantRow
                  rank={i + 1}
                  username={username}
                  userId={userId}
                  score={player.score}
                />
              </li>
            );
          return null;
        })}
      </ol>
    </div>
  );
};

export default Scores;
