import people from '../../images/icons/people.svg';

const BoardParticipantRow = ({ rank, username, userId, score }) => (
  <>
    <div data-type="rank" className="col--square">
      {rank}
    </div>
    <div data-type="name" style={{ display: 'inline-flex' }}>
      <div style={{ minWidth: '2rem', width: '2rem' }}>
        <div
          className="badge badge--small badge--circle"
          style={{ lineHeight: '1.8rem' }}
        >
          <img src={people} alt="" style={{ width: '1rem' }} />
        </div>
      </div>
      <div style={{ display: 'flex' }}>
        <p
          style={{
            display: 'grid',
            margin: '0',
            // minWidth: '10.5rem',
          }}
        >
          <span
            style={{
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            }}
          >
            {`${username}`}
          </span>
          <span
            style={{
              fontWeight: 'normal',
              fontSize: '10px',
              lineHeight: '10px',
            }}
          >
            {`(#${userId})`}
          </span>
        </p>
      </div>
    </div>
    <div data-type="score" className="col--square col--alignright">
      {score < 100 ? '' : score}
    </div>
  </>
);

export default BoardParticipantRow;
