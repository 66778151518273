import React from 'react';

import { calculatePercentage } from '../../../core/helper';

const Answers = ({
  question,
  onAnswerClick,
  showResults,
  isCalculating,
  triviaState = { eliminated: 0 },
  username,
}) => (
  <div className="card__answer card__answer--mctext">
    {question.answers.map((answer, i) => (
      <button
        className={`button button--answer ${
          ((isCalculating || triviaState.eliminated) && !answer.voted) ||
          !username
            ? 'isDisabled'
            : ''
        } ${answer.voted ? 'isSelected' : ''} 
            ${
              answer.voted && answer.is_right_answer && showResults
                ? 'isCorrect'
                : ''
            }
            ${
              answer.voted && !answer.is_right_answer && showResults
                ? 'isWrong'
                : ''
            }
            ${
              !answer.voted && answer.is_right_answer && showResults
                ? 'isCorrect'
                : ''
            }
          `}
        onClick={(event) => onAnswerClick(event, answer.id)}
        key={i}
        data-answer-id={answer.id}
      >
        <span className="button__text">{answer.title}</span>
        <span className="button__stat">
          <span
            style={{
              width: `${calculatePercentage(
                question.vote_count,
                answer.vote_count
              )}%`,
            }}
          />
          <b>
            {calculatePercentage(
              question.vote_count || 1,
              answer.vote_count
            ).toFixed(1)}
            %
          </b>
        </span>
      </button>
    ))}
  </div>
);

export default Answers;
