import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  Loading: {
    id: 'Player.Loading',
    defaultMessage: 'Loading',
  },
  Accepting: {
    id: 'Player.TriviaAccepting',
    defaultMessage: 'Trivia is accepting players',
  },
  TriviaActive: {
    id: 'Player.TriviaActive',
    defaultMessage: 'Trivia is active',
  },
  TriviaWaiting: {
    id: 'Player.TriviaWaiting',
    defaultMessage: 'Waiting for trivia round to start...',
  },
  ParticipatingAs: {
    id: 'Player.ParticipatingAs',
    defaultMessage: 'Participating as:',
  },
  EnterYourName: {
    id: 'Player.EnterYourName',
    defaultMessage: 'Register to play!',
  },
  Join: {
    id: 'Player.Join',
    defaultMessage: 'Join',
  },
  PeopleParticipating: {
    id: 'Player.PeopleParticipating',
    defaultMessage: 'people participating',
  },
  StayTuned: {
    id: 'Player.StayTuned',
    defaultMessage: 'Stay tuned for the next question...',
  },
  Eliminated: {
    id: 'Player.Eliminated',
    defaultMessage: 'You are eliminated!',
  },
  PeopleLoggedIn: {
    id: 'Player.PeopleLoggedIn',
    defaultMessage: 'people logged in',
  },
  Calculating: {
    id: 'Player.Calculating',
    defaultMessage: 'Calculating result...',
  },
  Question: {
    id: 'Player.Question',
    defaultMessage: 'Question',
  },
  CorrectAnswers: {
    id: 'Player.CorrectAnswers',
    defaultMessage: 'correct answers',
  },
  Correct: {
    id: 'Player.Correct',
    defaultMessage: 'Correct',
  },
  Wrong: {
    id: 'Player.Wrong',
    defaultMessage: 'Wrong',
  },
  Play: {
    id: 'Player.Play',
    defaultMessage: 'Play',
  },
  EnterTriviaCode: {
    id: 'Player.EnterTriviaCode',
    defaultMessage: 'Enter Trivia Code',
  },
  GoToSite: {
    id: 'Player.GoToSite',
    defaultMessage: 'Go To Site',
  },
  ValidUsername: {
    id: 'Player.ValidUsername',
    defaultMessage: 'Please enter a valid user name',
  },
  ValidEmail: {
    id: 'Player.ValidEmail',
    defaultMessage: 'Please enter a valid email',
  },
  ValidPhone: {
    id: 'Player.ValidPhone',
    defaultMessage: 'Please enter a valid phone number',
  },
  ValidComment: {
    id: 'Player.ValidComment',
    defaultMessage: 'Please enter a comment',
  },
  ValidTc: {
    id: 'Player.ValidTc',
    defaultMessage: 'Please agree to terms and conditions',
  },
  ViewerMode: {
    id: 'Player.ViewerMode',
    defaultMessage: 'Viewer Mode',
  },
  Online: {
    id: 'Player.Online',
    defaultMessage: 'Online',
  },
  Reconnecting: {
    id: 'Player.Reconnecting',
    defaultMessage: 'Trying to reconnect...',
  },
});
