import { defineMessages } from 'react-intl.macro';

export default defineMessages({
  WrongComb: {
    id: 'Login.WrongComb',
    defaultMessage: 'Wrong email/password combination!',
  },
  LoginToManage: {
    id: 'Login.LoginToManage',
    defaultMessage: 'Login To Manage',
  },
  Enter: {
    id: 'Login.Enter',
    defaultMessage: 'Enter',
  },
});
