/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

import cross from '../../../images/icons/cross.svg';
import BoardParticipantRow from '../../Board/BoardParticipantRow';

const Board = ({ players, data, closeBoard, messages, formatMessage }) => (
  <div
    className="leaderboard"
    style={{ minHeight: '300px', height: '100%', minWidth: '20rem' }}
  >
    <div style={{ textAlign: 'left', paddingTop: '10px' }}>
      <a
        href=""
        onClick={closeBoard}
        style={{
          width: '2rem',
          padding: '1rem 1rem',
        }}
      >
        <img src={cross} alt="" style={{ width: '1.5rem', opacity: '0.25' }} />
      </a>
    </div>
    <div className="leaderboard__header">
      <h1 style={{ textAlign: 'center' }}>{data.title}</h1>
    </div>
    <div className="leaderboard__main">
      <div className="leaderboard__rightcol">
        <div id="score" className="card card--score isActive">
          <ol className="card__leaderboard">
            {players.map((player, i) => {
              const [userId, username] = player?.player?.split('-') || [
                null,
                '',
              ];
              return (
                <li key={i}>
                  <BoardParticipantRow
                    rank={i + 1}
                    username={username}
                    userId={userId}
                    score={player.score}
                  />
                </li>
              );
            })}
          </ol>
        </div>
      </div>
    </div>
    <div className="card__followup" style={{ marginTop: '30px' }}>
      <div className="card__followup-overlay isVisible">
        <div className="card__followup--content">
          <div className="card__followup--actions">
            <small className="text--light">
              {formatMessage(messages.StayTuned)}
            </small>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Board;
