/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';

import { footerLogo } from '../../../core/constants';
import people from '../../../images/icons/people.svg';

const Footer = ({
  messages,
  formatMessage,
  socketData,
  connected,
  data = {},
}) => (
  <div className="leaderboard__footer">
    <div className="leaderboard__footer__left">
      <div>
        {data.embed_footer_img ? (
          <a
            href=""
            onClick={(e) => e.preventDefault()}
            style={{ display: 'block', padding: '0.675rem 0.75rem 0rem' }}
          >
            <img
              src={`${footerLogo}/${data.embed_footer_img}`}
              alt=""
              style={{ height: '1.5rem' }}
            />
          </a>
        ) : null}
      </div>
    </div>
    <div className="leaderboard__footer__centre">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <h4 className="sm-hidden">{`${formatMessage(messages.Question)}`}</h4>
        <h4
          style={{ marginLeft: '0.5rem' }}
        >{`${socketData.current_question}/${socketData.total_questions}`}</h4>
      </div>
      <div className="sequence__progress">
        <div
          className="sequence__progress-bar sequence__progress-bar"
          style={{
            width: `${
              (socketData.current_question / socketData.total_questions) * 100
            }%`,
          }}
        />
      </div>
    </div>
    <div className="leaderboard__footer__right">
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <span
          className="hasTooltip md-mr-0"
          style={{ paddingRight: '15px', marginRight: '15px' }}
        >
          {connected ? (
            <Fragment>
              <div
                className="dot-green"
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              />
              <span className="tooltip">{formatMessage(messages.Online)}</span>
            </Fragment>
          ) : (
            <Fragment>
              <div
                className="dot-red"
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              />
              <span className="tooltip">
                {formatMessage(messages.Reconnecting)}
              </span>
            </Fragment>
          )}
        </span>
        <div className="badge badge--status">
          <div>
            <img src={people} alt="" style={{ width: '1rem' }} />
          </div>
          <div>
            <span className="md-mr-1_5">
              <b className="value">{socketData.player_count}</b>
            </span>
            <span
              className="md-hidden"
              style={{ marginLeft: '0.5rem', marginRight: '1.5rem' }}
            >
              {`${formatMessage(messages.PeopleParticipating)}`}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
